import React, { useEffect } from 'react'
import  { isEmpty } from 'lodash'
import { Alert, Layout, Menu, Space, Spin } from 'antd'
import { Link, Navigate, Outlet, useLocation, useNavigate } from 'react-router-dom'
import { BulbOutlined, UserOutlined } from '@ant-design/icons'
import LeftHeader from './LeftHeader'
import RightHeader from './RightHeader'
import Logo from '../../components/UI/Logos/Logo'
import Icon from '../../components/UI/Icons/Icons'
import { useAppSelector } from '../../hooks/appHook'
import { AccountStatusEnum } from '../../types/account.type'
import { useGetAccountQuery } from '../../store/api/account.api'
import AccountMenu from '../../components/AccountMenu/AccountMenu'
import InviteToTeamModal from '../../components/UI/Modals/InviteToTeamModal'
import SiderMenuFooter from '../../components/SiderMenuFooter/SiderMenuFooter'
import { AccountRolesEnum, RolesEnum } from '../../config/rolesEnum'
import {
  ACCOUNTS,
  ADMIN_USER_ID,
  CONVERSATIONS,
  KNOWLEDGE_BASE,
  ONBOARDING_ROUTE,
  PROFILE_ROUTE,
  SIGNIN_ROUTE,
} from '../../config/consts'
import ApplicationMenu from '../../components/ApplicationMenu/ApplicationMenu'
import { LocalStorage } from 'ts-localstorage'
import './mainLayout.less'

const { Header, Content, Sider } = Layout

const MainLayout = () => {
  const navigate = useNavigate()
  const location = useLocation()

  const adminUserId = LocalStorage.getItem(ADMIN_USER_ID)

  /** Storage */
  const { user } = useAppSelector((state) => state.profile)
  const { account } = useAppSelector((state) => state.account)
  const { data, isLoading } = useGetAccountQuery(undefined, { skip: !user.id }) // const { currentAgentId } = useAppSelector((state) => state.agents)

  const sliderMenuItems = [
    {
      label: <Link to="/chat">Safety observation</Link>,
      key: '/chat',
      icon: <Icon name="chat" />,
      path: '/chat',
      title: 'Safety observation',
      index: 1,
    },
    {
      label: <Link to="/site-map">Project sitemap</Link>,
      key: '/site-map',
      icon: <Icon name="projectSiteMap" />,
      path: '/site-map',
      title: 'Project sitemap',
      index: 2,
    },
    {
      label: <Link to="/conversations">Observation History</Link>,
      key: '/conversations',
      icon: <Icon name="conversations" />,
      path: CONVERSATIONS,
      title: 'Observation History',
      index: 3,
    },
    {
      label: <Link to="/profile">My Profile</Link>,
      key: 'MyProfile',
      path: PROFILE_ROUTE,
      title: 'My Profile',
      icon: <UserOutlined style={{ fontSize: '22px' }} />,
      index: 6,
    },
  ]


  const storageItem = {
    label: <Link to="/storage">Knowledge Base</Link>,
    key: '/storage',
    icon: <Icon name="addData" width={23} height={23} />,
    path: KNOWLEDGE_BASE,
    title: 'Knowledge Base',
    index: 4,
  }
  const accountsItem = {
    label: <Link to="/admin/accounts">Accounts</Link>,
    key: 'admin/accounts',
    icon: <BulbOutlined style={{ fontSize: '22px' }} />,
    path: ACCOUNTS,
    title: 'Accounts',
    index: 5,
  }


  useEffect(() => {
    document.body.style.overflow = 'auto'
  }, [])

  useEffect(() => {
    if (isEmpty(data)) {
      return
    }

    const isOnboarding = account.status === AccountStatusEnum.ONBOARD

    if (isOnboarding && location.pathname !== ONBOARDING_ROUTE) {
      return navigate(ONBOARDING_ROUTE)
    }
  }, [data, account])

  if (!user?.id) {
    return <Navigate to={SIGNIN_ROUTE} state={{ from: location }} />
  }

  const defineMenuItems = () => {
    const menuItems = [...sliderMenuItems]

    if (user?.role === RolesEnum.ADMIN) {
      menuItems.push(storageItem)
    }
    if (user?.role === RolesEnum.ADMIN || user?.accountRole === AccountRolesEnum.ADMIN) {
      menuItems.push(accountsItem)
    }


    return menuItems
  }

  const menuItems = defineMenuItems().sort((a, b) => a.index - b.index)


  return (
    <div>
      <div className="bg-shape" />
      <div className="bg-shape" />
      <div className="bg-shape" />
      <div className="bg-shape" />
      <Spin spinning={isLoading}>
        <Layout id="ioni-main-layout">
          <InviteToTeamModal />
          <Sider width={274} id="ioni-layout-sider" breakpoint="lg" collapsedWidth="0" className="ioni-layout-sider">
            <Link to="/" className="logo">
              <Logo name="safetyLensDefault" />
            </Link>
            <Menu
              mode={'inline'}
              style={{
                width: 256,
              }}
              items={menuItems}
              selectedKeys={[location.pathname]}
            />
            <div className="sider-menu-footer">
              <SiderMenuFooter />
            </div>
          </Sider>
          <Layout>
            <Header>
              <div className="header">
                <div className="right-header-item">
                  <Space size={25}>
                    <div>
                      <Link to="/" className="logo">
                        <Logo name="safetyLensDefault" />
                      </Link>
                      <RightHeader />
                      <AccountMenu />
                    </div>
                  </Space>
                </div>
                {!!adminUserId && <p id="admin-account-email">{user.email}</p>}
                <ApplicationMenu
                  items={defineMenuItems()}
                />
                {user?.status === 'unverified' && (
                  <Alert
                    message={
                      <>
                        Please verify your email address {user.email} to use Ioni. Resend verification email on
                        your{' '}
                        <Link to="/profile">profile page</Link>.
                      </>
                    }
                    type="warning"
                    style={{ marginTop: 20 }}
                  />
                )}
                <div className="left-header-item">
                  <LeftHeader />
                </div>
              </div>
            </Header>
            <Content className="content">
              <Outlet />
            </Content>
          </Layout>
        </Layout>
      </Spin>
    </div>
  )
}

export default MainLayout
