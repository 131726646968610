import { LocalKey } from 'ts-localstorage'

export const API_URL = window.location.href.includes('staging')
  ? 'https://api-staging.safetylens.ai'
  : "https://api.safetylens.ai";

// export const API_URL = "http://localhost:4001"


export const WIDGET_URL =
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  API_URL === 'https://api.safetylens.ai' ? 'widget.ioni.ai' : 'staging.widget.ioni.ai'
export const CHAT_URL =
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  API_URL === 'https://api.safetylens.ai' ? 'app.ioni.ai' : 'staging.ioni.ai'

export const DATA_HOST =
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  API_URL !== 'https://api.safetylens.ai' ? 'https://staging.widget.ioni.ai' : ''

export const IS_PRODUCTION = process.env.NODE_ENV === 'production'

export const AUTH_ROUTE = '/auth'
export const INVITE_ROUTE = '/invite'
export const PROFILE_ROUTE = '/profile'
export const PROFILE_EDIT_ROUTE = '/profile/edit'

export const CHROMA_TEST_ROUTE = '/debug'
export const AI_HELPER_ROUTE = '/'
export const AI_HELPER_UPDATE_DATA_ROUTE = '/add-data'
export const AI_HELPER_EDIT_DATA_ROUTE = '/edit-data'
export const KNOWLEDGE_BASE = '/storage'
export const ADMIN_USERS_ROUTE = '/team'
export const ADMIN_INVITE_ROUTE = '/invite'
export const DASHBOARD_ROUTE = '/dashboard'
export const SINGLE_TICKET_ROUTE = '/ticket'
export const TICKETS_ROUTE = '/ticket'
export const ACCOUNTS = '/admin/accounts'
export const ABOUT_IONI_ROUTE = '/'
export const CREATE_AI_ASSISTENT = '/agent'
export const CONVERSATIONS = '/conversations'

export const SIGNIN_ROUTE = '/signin'
export const SIGNUP_ROUTE = '/signup'
export const ONBOARDING_ROUTE = '/onboarding'
export const FORGOT_PASSWORD = '/forgot-password'

export const ACCESS_TOKEN = new LocalKey('accessToken', '')
export const REFRESH_TOKEN = new LocalKey('refreshToken', '')
export const ADMIN_USER_ID = new LocalKey('adminUserId', '')
export const ADMIN_ACCOUNT_ID = new LocalKey('adminAccountId', '')

export const IMAGES_URL = '/images'
export const LOGO_URL = IMAGES_URL + '/logo/'
