import React from 'react'
import { Button, Form, Input } from 'antd'
import { Link, useLocation } from 'react-router-dom'
import TopFloater from './TopFloater'
import { IAuth } from '../../types/auth.type'
import '../../layouts/guestLayout/guestLayout.less'
import { useErrorMessage } from '../../hooks/useErrorMessage'
//import {useSuccessMessage} from '../../hooks/useSuccessMessage'
import AppPrimaryButton from '../../components/UI/AppPrimaryButton/AppPrimaryButton'
import { FORGOT_PASSWORD } from '../../config/consts'
import { useSignInMutation, useSignUpMutation } from '../../store/api/auth.api'
import Checkbox from 'antd/es/checkbox/Checkbox'
import { minLengthValidator, requiredValidator } from '../../helpers/validators'
import { accountApi } from '../../store/api/account.api'
import { userResetState } from '../../store/slices/profile.slice'
import { useDispatch } from 'react-redux'
import Logo from '../../components/UI/Logos/Logo'
import { useSuccessMessage } from '../../hooks/useSuccessMessage'

interface Props {
  isSignup?: boolean
}

const SignIn = ({ isSignup }: Props) => {
  const dispatch = useDispatch()
  const [signIn, { error: signInError, status: signInStatus }] = useSignInMutation()
  const [signUp, { error: signUpError, status: signUpStatus, isSuccess }] = useSignUpMutation()

  const location = useLocation()
  const queryParams = new URLSearchParams(location.search)
  const accountId = queryParams.get('accountId') || undefined

  const onFinish = (values: IAuth) => {
    if (isSignup) {
      signUp({ accountId, ...values })
      return
    } else {
      localStorage.clear()
      dispatch(userResetState())
      dispatch(accountApi.util.resetApiState())
      signIn(values)
    }
  }

  useErrorMessage('Invalid credentials', signInError || signUpError)

  useSuccessMessage(`Successfully`, isSuccess)

  return (
    <>
      <Button id="logo-btn" type="link" className="logo">
        <Logo name={'safetyLensDefault'} />
      </Button>
      <TopFloater isSignup={isSignup} />
      <div className="login-box">
        <Form
          name="login-form"
          initialValues={{ remember: true }}
          onFinish={onFinish}
          layout="vertical"
        >
          <p
            className="form-title">{isSignup ? (accountId ? 'Invite member to your SafetyLens account ' : 'Create account to try SafetyLens') : 'Sign in to your account'}</p>
          <p></p>
          {(isSignup && !accountId) && (
            <Form.Item
              name="accountName"
              rules={[{ required: true, validator: requiredValidator('Please input account Name!') }]}
            >
              <div>
                <p style={{ marginTop: 0 }}>Account name</p>
                <Input size="large"></Input>
              </div>
            </Form.Item>
          )}
          {(isSignup) && (
            <Form.Item
              name="userName"
              rules={[{ required: true, validator: requiredValidator('Please input user Name!') }]}
            >
              <div>
                <p style={{ marginTop: 0 }}>User name</p>
                <Input size="large"></Input>
              </div>
            </Form.Item>
          )}
          <Form.Item name="email"
                     rules={[{ required: true, validator: requiredValidator('Please input your Email!') }, {
                       type: 'email',
                       message: 'Please enter valid email address!',
                     }]}>
            <div>
              <p>Email</p>
              <Input size="large"></Input>
            </div>
          </Form.Item>
          <Form.Item name="password" rules={[{
            required: true,
            validator: requiredValidator('Please input your Password!'),
          }, isSignup ? { validator: minLengthValidator(8, 'Password') } : {}]}>
            <div>
              <p>Password</p>
              <Input.Password size="large"></Input.Password>
            </div>
          </Form.Item>
          <Form.Item>
            <div className="login-form-actions">
              {isSignup && (
                <Form.Item
                  name="agree"
                  valuePropName="checked"
                  rules={[
                    {
                      validator: (_, value) =>
                        value
                          ? Promise.resolve()
                          : Promise.reject(new Error('Should accept Terms & Conditions and Privacy Policy')),
                    },
                  ]}
                >
                  <Checkbox>
                    <span style={{ color: '#FF5996', marginRight: 2 }}>*</span>
                    Please accept our{' '}
                    <Link to="https://safetylens.ai/terms" target="_blank">
                      Terms & Conditions
                    </Link>{' '}
                    and{' '}
                    <Link to="https://safetylens.ai/privacy-policy" target="_blank">
                      Privacy Policy
                    </Link>
                  </Checkbox>
                </Form.Item>
              )}
              {!isSignup && (
                <div id="forgot-password-text-container">
                  <Link to={FORGOT_PASSWORD}>
                    Forgot password?
                  </Link>
                </div>
              )}
            </div>
          </Form.Item>

          <Form.Item>
            <AppPrimaryButton
              type="primary"
              size="middle"
              htmlType="submit"
              loading={signInStatus === 'pending' || signUpStatus === 'pending'}
              //className="login-form-button"
            >
              {isSignup ? 'Create account' : 'Sign In'}
            </AppPrimaryButton>
          </Form.Item>
          {/*<Divider plain>or</Divider>*/}
          {/*<Form.Item>*/}
          {/*	<Button href={`${API_URL}/auth/google`}*/}
          {/*		className="login-form-button"*/}
          {/*		//onClick={handleGoogleOAuth}*/}
          {/*	>*/}
          {/*		<GoogleOutlined/> Continue with Google &gt;*/}
          {/*	</Button>*/}
          {/*</Form.Item>*/}
        </Form>
      </div>
    </>
  )
}

export default SignIn
