import { configureStore } from '@reduxjs/toolkit'
import { authApi } from './api/auth.api'
import { accountApi } from './api/account.api'
import { messageApi } from './api/message.api'
import { publicApi } from './api/pubic.api'
import { appStateSlice } from './slices/appState.slice'
import { onboardingSlice } from './slices/onboardingSlice'
import { conversationApi } from './api/conversation.api'
import { uploadsApi } from './api/uploads.api'
import { usersApi } from './api/users.api'
import { usersSlice } from './slices/users.slice'
import { ticketApi } from './api/ticket.api'
import { ticketSlice } from './slices/ticket.slice'
import { profileApi } from './api/profile.api'
import { profileSlice } from './slices/profile.slice'
import { accountsApi } from './api/admin/accounts.api'
import { accountSlice } from './slices/account.slice'
import { agentApi } from './api/agent.api'
import { agentsSlice } from './slices/agents.slice'
import { storageApi } from './api/storage.api'
import { storageSlice } from './slices/storage.slice'
import { persistReducer, persistStore} from 'redux-persist'
import storage from 'redux-persist/lib/storage'

const profilePersistConfig = {
  key: 'profile',
  storage,
};

const persistedProfileReducer = persistReducer(profilePersistConfig, profileSlice.reducer);

export const store = configureStore({
  reducer: {
    [accountSlice.name]: accountSlice.reducer,
    [appStateSlice.name]: appStateSlice.reducer,
    [onboardingSlice.name]: onboardingSlice.reducer,
    [usersSlice.name]: usersSlice.reducer,
    [ticketSlice.name]: ticketSlice.reducer,
    [profileSlice.name]: persistedProfileReducer,
    [agentsSlice.name]: agentsSlice.reducer,
    [storageSlice.name]: storageSlice.reducer,

    [authApi.reducerPath]: authApi.reducer,
    [accountsApi.reducerPath]: accountsApi.reducer,
    [messageApi.reducerPath]: messageApi.reducer,
    [publicApi.reducerPath]: publicApi.reducer,
    [usersApi.reducerPath]: usersApi.reducer,
    [ticketApi.reducerPath]: ticketApi.reducer,
    [profileApi.reducerPath]: profileApi.reducer,
    [agentApi.reducerPath]: agentApi.reducer,
    [storageApi.reducerPath]: storageApi.reducer,
    [accountApi.reducerPath]: accountApi.reducer,
    [conversationApi.reducerPath]: conversationApi.reducer,
    [uploadsApi.reducerPath]: uploadsApi.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    })
      .concat(publicApi.middleware)
      .concat(authApi.middleware)
      .concat(profileApi.middleware)
      .concat(usersApi.middleware)
      .concat(ticketApi.middleware)
      .concat(accountApi.middleware)
      .concat(messageApi.middleware)
      .concat(accountsApi.middleware)
      .concat(conversationApi.middleware)
      .concat(agentApi.middleware)
      .concat(storageApi.middleware)
      .concat(uploadsApi.middleware),
})

export const persistor = persistStore(store);

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
